import axios, { AxiosInstance } from 'axios';

export class AxiosFactory {
  public static createInstance(baseURL: string): AxiosInstance {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };

    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('token');
      headers['Authorization'] = `Bearer ${token}`;
    }

    return axios.create({
      baseURL,
      headers,
    });
  }
}

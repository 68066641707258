import { Input } from 'antd';
import { FieldProps, TextareaFieldModel } from '..';
import { useTrans } from '../../hooks';
import { FieldError, FieldLabel, FieldNote } from './components';

const { TextArea } = Input;

export interface AcnTextAreaProps extends TextareaFieldModel, FieldProps {}

export const AcnTextArea = ({
  name,
  placeholder,
  label,
  error,
  onChange,
  hidden,
  value,
  disabled,
  rows = 4,
  note,
  maxLength,
  rules,
  hiddenLabel,
}: AcnTextAreaProps) => {
  const { t } = useTrans();
  const required = !!rules?.hasOwnProperty('required');

  return (
    !hidden && (
      <div className="mb-4 space-y-1" key={name}>
        <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
        <TextArea
          rows={rows}
          name={name}
          onChange={(e) => onChange(e.target.value)}
          maxLength={maxLength}
          disabled={!!disabled}
          value={value}
          placeholder={placeholder ? t(placeholder as any) : undefined}
          count={{ show: !!maxLength, max: maxLength }}
        />
        <FieldError error={error} />
        <FieldNote {...note} />
      </div>
    )
  );
};

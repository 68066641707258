import { Select } from 'antd';
import { BaseOptionType, SelectValue } from 'antd/es/select';
import { DropdownFieldModel, FieldProps } from '..';
import { useTrans } from '../../hooks';
import { FieldError, FieldLabel, FieldNote } from './components';

const { Option } = Select;

export interface AcnDropdownProps extends DropdownFieldModel, FieldProps {}
type OtherProps = {
  value?: string | string[] | number[];
  mode?: 'multiple' | 'tags';
};

export const AcnDropdown = ({
  name,
  placeholder,
  label,
  error,
  onChange,
  options,
  type,
  hidden,
  value,
  disabled,
  note,
  rules,
  showSearch = false,
  hiddenLabel,
}: AcnDropdownProps) => {
  const { t } = useTrans();
  const required = !!rules?.hasOwnProperty('required');

  let otherProps: OtherProps = {
    value,
  };

  if (type) {
    otherProps.mode = type;
    otherProps.value = type ? value || [] : value;
  }

  const handleChange = (value: SelectValue, option: BaseOptionType | BaseOptionType[]) => {
    const v = type ? [] : null;
    return onChange(value || v);
  };

  return (
    !hidden && (
      <div key={name}>
        <div className="mb-4 space-y-1" key={name}>
          <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
          <Select
            key={name}
            size="large"
            allowClear
            value={value}
            className="w-full"
            onChange={handleChange}
            placeholder={placeholder ? t(placeholder as any) : undefined}
            disabled={!!disabled}
            showSearch={showSearch}
            filterOption={(input, option: any) => {
              return option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
            }}
            {...otherProps}
          >
            {options?.map((o, index) => {
              return (
                <Option disabled={o.disabled} key={index + o.value} value={o.value}>
                  {t(o.label as any)}
                </Option>
              );
            })}
          </Select>
          <FieldError error={error} />
          <FieldNote {...note} />
        </div>
      </div>
    )
  );
};

import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AxiosFactory } from './AxiosFactory';

export interface ResponseError {
  message: string;
  statusCode: number;
}

export type Response<T> = { data: T; error: null } | { data: null; error: ResponseError };

export class ApiClient {
  private instance: AxiosInstance;

  constructor(baseUrl: string) {
    this.instance = AxiosFactory.createInstance(baseUrl);
  }

  private async request<T>(method: string, url: string, data?: any, config?: AxiosRequestConfig): Promise<Response<T>> {
    try {
      const response = await this.instance({
        method,
        url,
        data,
        ...config,
      });
      return {
        data: response.data,
        error: null,
      };
    } catch (error: any) {
      return {
        data: null,
        error: {
          message: error.message || 'An error occurred',
          statusCode: error.response?.status || 500,
        },
      };
    }
  }

  async get<T>(url: string): Promise<Response<T>> {
    return this.request<T>('GET', url);
  }

  async post<T>(url: string, data: any): Promise<Response<T>> {
    return this.request<T>('POST', url, data);
  }

  async put<T>(url: string, data: any): Promise<Response<T>> {
    return this.request<T>('PUT', url, data);
  }

  async patch<T>(url: string, data: any): Promise<Response<T>> {
    return this.request<T>('PATCH', url, data);
  }

  async delete<T>(url: string): Promise<Response<T>> {
    return this.request<T>('DELETE', url);
  }

  async upload<T>(url: string, data: FormData): Promise<Response<T>> {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return this.request<T>('POST', url, data, config);
  }
}

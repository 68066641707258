import { cn } from '@pkg/utils';
import Link, { LinkProps } from 'next/link';
import { ReactNode } from 'react';

interface AcnLinkProps extends LinkProps {
  children: ReactNode;
  className?: string;
}

export const AcnLink = ({ className, ...props }: AcnLinkProps) => {
  return <Link {...props} className={cn('text-gray-700', className)} />;
};

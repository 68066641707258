import { useTrans } from '../../..';

type FieldLabelProps = {
  label: string | undefined;
  required?: boolean;
  hiddenLabel?: boolean;
};

export const FieldLabel = ({ label, required, hiddenLabel }: FieldLabelProps) => {
  const { t } = useTrans();

  if (!label || hiddenLabel) return <></>;

  return (
    <div>
      {label ? <span className="text-sm">{t(label as any)}</span> : undefined}
      {!!required && <span className="text-red-600"> *</span>}
    </div>
  );
};
